import React from 'react';
import Page from '../components/PageTemplate'
import ClubText from '../data/TextAreaInput/ClubText'

const Club = () => {
    const titleSectionInput = {
        title: '',
        image: 'uppvarmning.jpg',
        bgTint1: "rgba(0, 37, 84, 0.2)",
        bgTint2: "rgba(0, 37, 84, 0.5)",
        bgTint3: "rgba(0, 37, 84, 0.8)"
    }
    return ( <
        Page titleSection = { titleSectionInput }
        textArea = { < ClubText / > }
        />
    )
}

export default Club