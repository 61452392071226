import React from 'react'
import { menuData } from '../../data/MenuData';
import {DropdownContainer, DropdownWrapper, DropdownMenu, DropdownLink} from './DropdownElements'


const Dropdown = ({isOpen, toggle}) => {
    return (
        <DropdownContainer isOpen={isOpen} onClick={toggle}>
            <DropdownWrapper>
                <DropdownMenu>
                    {menuData.map((item, index) => (
                        <DropdownLink to={item.link} key={index + 2}>
                            {item.title}
                        </DropdownLink>
                    ))}
                </DropdownMenu>
                {/* <DropdownButtonsWrapper>
                    <DropdownLink to='/anmal-intresse' key='0'>
                            Anmäl intresse
                    </DropdownLink>
                    <DropdownLink to='/logga-in' key='1'>
                            Logga in
                    </DropdownLink>
                </DropdownButtonsWrapper> */}
            </DropdownWrapper>
        </DropdownContainer>
    )
}

export default Dropdown
