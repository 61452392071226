import React from 'react' 
const ClubText = () =>{ return (
    <div>
        <h1>Djurgården Bordtennis</h1>
        <p>
            Djurgården Bordtennis är öppen för alla och har aktiva motions- och tävlingsspelare från 6 år till 80 år.
        </p>
        <p>
            Oavsett nuvarande spelnivå, tidigare tränings- eller tävlingserfarenhet kommer du snabbt att lära dig grunderna i pingis. För dig som verkligen vill utveckla ditt spel, spela i lag och tävla erbjuds goda möjligheter. 
        </p>
        <p>
            Har du en gång lagt grunderna på rätt sätt kan du när som helst återuppta pingisen längre fram.
        </p>
        <p>
            Är du redan etablerad pingisspelare - och djurgårdare - är du alltid välkommen på extra träningspass eller varför inte kröna karriären med några säsonger i djurgårdströjan!
        </p>
    </div>
    ); 
};

export default ClubText