import styled, { css } from 'styled-components/macro'
import { NavLink as Link } from 'react-router-dom';

export const Nav = styled.nav`
    background: transparent;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
`;

export const NavLink = css`
    background-color: #002554;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 60px;
    cursor: pointer;
    font-family: 'Didact Gothic', sans-serif;
    font-size: 1.1rem;

    &:link {
        text-decoration: none;
    }

    &:hover {
        color: #fff;
        background-color: #32baf2;
    }
`;

export const Logo = styled(Link)`
    margin-left: -20px;
    margin-top: -5px;
    width: '78px';
    height: '78px';

    &:hover {
        opacity: 0.5;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -35px;
`;

export const NavMenuLink = styled(Link)`
    ${NavLink}
`;

export const NavButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const MenuBars = styled.div`
    ${NavLink}
`;

export const MenuBarsIcon = styled.div`
    margin-left: 15px;
    margin-top: -1px;
`;