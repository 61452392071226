import React, { Component , useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components/macro';
// import './SubmitInterest.css';
import { DataStore } from '@aws-amplify/datastore';
import { TTSchoolRegistration, TTSchoolEvent, TtSchoolRegistrationStatus } from '../models';

const SubmitInterestWrapper = styled.div`
    //background: red;
`;

const ImgTintLayer = styled.div`
    position: "absolute";
    background-color: #cfb571;
    height: "100vh";
    width: "100vw";
    display: flex;
`;

const TextArea = styled.div`
    max-width: 70%;
    margin-top: 50px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 100px;
    font-size: 20px;
    text-align: left;
    font-family: 'Didact Gothic', sans-serif;
`;

const FormWrapper = styled.div`
    max-width: 70%;
    margin-top: 50px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 100px;
    font-size: 20px;
    text-align: left;
    font-family: 'Didact Gothic', sans-serif;
`;

const handleSubmit = (event) => {
    const form = event.currentTarget;
    console.log(form[0])
  };


const SubmitInterest = () => {
    const [ form, setForm ] = useState({})
    const [ errors, setErrors ] = useState({})
    const maxAge = 100
    const minAge = 1

    const setField = (field, value) => {
        setForm({
          ...form,
          [field]: value
        })
        // Check and see if errors exist, and remove them from the error object:
        if ( !!errors[field] ) setErrors({
          ...errors,
          [field]: null
        })
    }

    const findFormErrors = () => {
        const { first_name, last_name, personal_number, hasttequipment, school_name, tableaccess, other_sports, 
                                            guardian_phone, guardian_first_name, guardian_last_name, guardian_email } = form
        const thisYear = new Date().getFullYear()
        const newErrors = {}
        if ( !first_name || first_name === '' ) newErrors.first_name = 'Skriv in ett förnamn'
        else if ( first_name.length > 50 ) newErrors.first_name = 'Förnamnet är för långt'
        if ( !last_name || last_name === '' ) newErrors.last_name = 'Skriv in ett efternamn'
        else if ( last_name.length > 50 ) newErrors.last_name = 'Efternamnet är för långt'
        if ( personal_number ) {
            //if ( !Number.isInteger(personal_number) ) newErrors.personal_number = 'Skriv in personnumret med 12 siffror'
            if ( personal_number.length != 12 ) newErrors.personal_number = 'Skriv in personnumret med 12 siffror'
            else if ( personal_number.substring(4,6) < 1 || personal_number.substring(4,6) > 12 ) newErrors.personal_number = 'Fel format på angivet personnummer'
            else if ( personal_number.substring(6,8) < 1 || personal_number.substring(6,8) > 31 ) newErrors.personal_number = 'Fel format på angivet personnummer'
            else if ( personal_number.substring(0,4) < thisYear-maxAge || personal_number.substring(0,4) > thisYear-minAge ) newErrors.personal_number = 'Fel format på angivet personnummer'
        } else {
            newErrors.personal_number = 'Skriv in personnumret med 12 siffror'
        }
        if ( school_name ) {
            if ( school_name.length > 50 ) newErrors.first_name = 'Skolnamnet är för långt'
        }
        if ( !guardian_phone || guardian_phone === '' || guardian_phone.length < 7 ) newErrors.guardian_phone = 'Ej giltigt telefonnummer'
        else if ( guardian_phone.length > 20 ) newErrors.guardian_phone = 'Telefonnumret är för långt'
        if ( !guardian_first_name ) newErrors.guardian_first_name = 'Skriv in ett förnamn'
        else if ( guardian_first_name.length > 50 ) newErrors.guardian_first_name = 'Förnamnet är för långt'
        if ( !guardian_last_name ) newErrors.guardian_last_name = 'Skriv in ett efternamn'
        else if ( guardian_last_name.length > 50 ) newErrors.guardian_last_name = 'Efternamnet är för långt'
        if ( guardian_email ) {
            if ( guardian_email.includes(" ") || !guardian_email.includes("@") || guardian_email.includes("@.") ) newErrors.guardian_email = 'Mailadressen är inte giltig'
            else if ( !guardian_email.split("@")[1].includes(".") || guardian_email.split("@")[1].includes("@") || guardian_email.split("@")[1].split(".") == "" || guardian_email.split("@")[1].split(".")[1] == "" ) newErrors.guardian_email = 'Mailadressen är inte giltig'
        }
    
        return newErrors
    }
    
    const handleSubmit = e => {
        // prevent default behaviour and check for errors first
        e.preventDefault()
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
          setErrors(newErrors)
        } else {
            // No errors! Put any logic here for the form submission!
            // Access the data through form.[field] where field is in {first_name, last_name, personal_number, hasttequipment, 
            //              school_name, tableaccess, other_sports, guardian_phone, guardian_first_name, guardian_last_name, guardian_email}
            // alert(form.first_name)
            // alert(form.last_name)
            // alert(form.personal_number)
            // alert(form.hasttequipment)
            // alert(form.school_name)
            // alert(form.tableaccess)
            // alert(form.other_sports)
            // alert(form.guardian_phone)
            // alert(form.guardian_first_name)
            // alert(form.guardian_last_name)
            // alert(form.guardian_email)

            (async () => {
                await DataStore.save(
                    new TTSchoolRegistration({
                        "first_name": form.first_name,
                        "last_name": form.last_name,
                        "personal_number": form.personal_number,
                        "has_tt_equipment": (form.hasttequipment == 'true'),
                        "school_name": form.school_name,
                        "has_tt_table_access": (form.tableaccess == 'true'),
                        "other_sports": form.other_sports,
                        "first_guardian_phone": form.guardian_phone,
                        "first_guardian_first_name": form.guardian_first_name,
                        "first_guardian_last_name": form.guardian_last_name,
                        "first_guardian_email": form.guardian_email,
                        "status": TtSchoolRegistrationStatus.PENDING,
                    })
                );
            })();
        }
    }

    return (
        <SubmitInterestWrapper>
            <ImgTintLayer/>
            <TextArea>
                <div className="gap"></div>
                <h1>
                Anmäl intresse
                </h1>
            </TextArea>

            <FormWrapper>
                <Form>
                    <Form.Group as={Row} controlId="first_name">
                        <Form.Label column sm={4}>Förnamn*</Form.Label>
                        <Col><Form.Control 
                            type='text'
                            onChange={ e => setField('first_name', e.target.value) }
                            isInvalid={ !!errors.first_name }
                            placeholder="Förnamn, ex. John" required
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.first_name }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="last_name">
                        <Form.Label column sm={4}>Efternamn*</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('last_name', e.target.value) }
                            isInvalid={ !!errors.last_name }
                            placeholder="Efternamn, ex. Andersson" required
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.last_name }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="personal_number">
                        <Form.Label column sm={4}>Personnummer*</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('personal_number', e.target.value) }
                            isInvalid={ !!errors.personal_number }
                            placeholder="ÅÅÅÅMMDDNNNN" required
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.personal_number }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <fieldset>
                        <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Har du bordtennisutrustning?
                        </Form.Label>
                        <Col>
                            <Form.Check
                                type="radio"
                                label="Ja"
                                name="hasttequipment"
                                id="hasttequipmentyes"
                                value="true"
                                onChange={ e => setField('hasttequipment', e.target.value) }
                            />
                            <Form.Check
                                type="radio"
                                label="Nej"
                                name="hasttequipment"
                                id="hasttequipmentno"
                                value="false"
                                onChange={ e => setField('hasttequipment', e.target.value) }
                            />
                        </Col>
                        </Form.Group>
                    </fieldset>
                    <Form.Group as={Row} controlId="school_name">
                        <Form.Label column sm={4}>Skola</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('school_name', e.target.value) }
                            isInvalid={ !!errors.school_name }
                            placeholder="Skolans namn"
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.school_name }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <fieldset>
                        <Form.Group as={Row}>
                        <Form.Label column sm={4}>
                            Har du tillgång till bordtennisbord?
                        </Form.Label>
                        <Col>
                            <Form.Check
                            type="radio"
                            label="Ja"
                            name="tableaccess"
                            id="tableaccessyes"
                            value="true"
                            onChange={ e => setField('tableaccess', e.target.value) }
                            />
                            <Form.Check
                            type="radio"
                            label="Nej"
                            name="tableaccess"
                            id="tableaccessno"
                            value="false"
                            onChange={ e => setField('tableaccess', e.target.value) }
                            />
                        </Col>
                        </Form.Group>
                    </fieldset>
                    <Form.Group as={Row} controlId="other_sports">
                        <Form.Label column sm={4}>Andra sporter?</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('other_sports', e.target.value) }
                            isInvalid={ !!errors.other_sports }
                            placeholder="Ex. Fotboll, curling, frisbeegolf"
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.other_sports }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="guardian_phone">
                        <Form.Label column sm={4}>Målsmans telefonnummer*</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('guardian_phone', e.target.value) }
                            isInvalid={ !!errors.guardian_phone }
                            placeholder="Telefonnummer" required
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.guardian_phone }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="guardian_first_name">
                        <Form.Label column sm={4}>Målsmans förnamn*</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('guardian_first_name', e.target.value) }
                            isInvalid={ !!errors.guardian_first_name }
                            placeholder="Målsmans förnamn" required
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.guardian_first_name }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="guardian_last_name">
                        <Form.Label column sm={4}>Målsmans efternamn*</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('guardian_last_name', e.target.value) }
                            isInvalid={ !!errors.guardian_last_name }
                            placeholder="Målsmans efternamn" required
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.guardian_last_name }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="guardian_email">
                        <Form.Label column sm={4}>Målsmans mailadress</Form.Label>
                        <Col><Form.Control 
                            type='text' 
                            onChange={ e => setField('guardian_email', e.target.value) }
                            isInvalid={ !!errors.guardian_email }
                            placeholder="namn@exempel.se"
                        />
                        <Form.Control.Feedback type='invalid'>
                            { errors.guardian_email }
                        </Form.Control.Feedback>
                        </Col>
                        <Col></Col>
                    </Form.Group>

                    <Button variant="primary" type="submit" onClick={ handleSubmit }>
                        Skicka
                    </Button>
                </Form>
            </FormWrapper>
        </SubmitInterestWrapper>
    );
}

export default SubmitInterest;
