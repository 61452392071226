import React, {useState, useEffect} from 'react';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

const MyPages = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
        setUser(authData)
    });
}, []);

  return (
    <div className="MyPages">
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
        Hi {AuthState.SignedIn && user ? user.attributes["email"] : ""}
        <AmplifySignOut/>
    </div>
  );
}

export default withAuthenticator(MyPages);
