import React from 'react'
import TitleSection from '../TitleSection'
import PropTypes from "prop-types";
import "./pages.css";

export default class Page extends React.Component {
    static propTypes = {
      titleSection: PropTypes.shape({
        title: PropTypes.string,
        bgTint1: PropTypes.string,
        bgTint2: PropTypes.string,
        bgTint3: PropTypes.string
      })
    };
  
    render() {
      const titleSection = this.props.titleSection;
      var textSection = this.props.textArea;
      return (
        <div className="component-wrapper-base">
            <TitleSection 
                title={titleSection.title} 
                bgImage={titleSection.image}
                bgTint1={titleSection.bgTint1}
                bgTint2={titleSection.bgTint2}
                bgTint3={titleSection.bgTint3}
                />
            
            <div className="component-textarea">
                {textSection}
            </div>
        </div>
      );
    }
  }


