import React from 'react';
import styled from 'styled-components';
import TitleSection from '../components/TitleSection'
// import final_dif_vardegrund from '../images/final_dif_vardegrund.png';

const ValuesWrapper = styled.div`
`;

const TextArea = styled.div`
    max-width: 70%;
    margin-top: 50px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 100px;
    font-size: 20px;
    text-align: left;
    font-family: 'Didact Gothic', sans-serif;
`;

function Values() {
  return (
    <ValuesWrapper>
      <TitleSection 
        title={`Värdegrund`}
        subTitle={`Värdegrund för Djurgårdens idrottsförening`}
        bgTint1={"rgba(0, 37, 84, 0.2)"}
        bgTint2={"rgba(0, 37, 84, 0.5)"}
        bgTint3={"rgba(0, 37, 84, 0.8)"}
        />
      <TextArea>
      <p>
         Den 9 mars 2016 beslutade Djurgårdens Alliansförenings årsmöte enhälligt att anta Djurgårdens nya värdegrund. Värdegrunden sprids nu inom Djurgården och ingår i våra utbildningar.
       </p>
       <p>
         Värdegrunden är ett resultat av 18 månaders arbete där samtliga allians- och supporterföreningar och fler än 8 000 Djurgårdare engagerat sig.
        </p>
        <p>
         Värdegrunden ska på sikt genomsyra hela Djurgårdens IF. Syftet är att stärka Djurgårdens identitet som ledande idrottsförening i Sverige och roll som en positiv kraft i samhället. Ett stöd för ytterligare 125 framgångsrika år.
       </p>
      </TextArea>
    </ValuesWrapper>
  );
}

export default Values;
