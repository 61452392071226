import React from 'react' 
const TrainingClassesText = () =>{ return (
    <div>
        <h1>Pingisskolor</h1>
        <p>
            Vem har inte spelat pingis i skolan och på fritids, på kontoret, i källare eller rentav utomhus. Spellokalerna kan minst sagt variera rejält liksom spelstilarna.
        </p>
        <p>
            Genom att bädda för en bra teknik öppnas spelets alla möjligheter och dimensioner. Det som förenar skolade pingisspelare är alltså att tekniken och spelförståelsen är väl omhändertagen.
        </p>
        <p>
            Välkommen att anmäla dig till någon av våra många pingisskolor. Kontakta kansliet på <a href="mailto:difbtx@gmail.com">difbtx@gmail.com</a> för att få information om hur du går till väga.
        </p>
    </div>
    ); 
};

export default TrainingClassesText