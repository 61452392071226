import styled from 'styled-components/macro'
import { NavLink as Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export const DropdownContainer = styled.div`
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: #002554;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.0rem;
    right: 0.8rem;
    background: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    outline: none;
`;

export const CloseIcon = styled(FaTimes)`
    color: #fff;

    &:hover {
        color: #cfb571
    }
`;

export const DropdownWrapper = styled.div``;

export const DropdownMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat()(4, 80px);
    text-align: center;
    margin-top:4rem;
    margin-bottom: 4rem;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(4, 60px);
    }
`;

export const DropdownLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'dif_displaymedium';

    &:link {
        text-decoration: none;
    }

    &:hover {
        color: #cfb571
    }
`;


export const DropdownButtonsWrapper = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat()(4, 80px);
        text-align: center;
        margin-bottom: 4rem;
    }

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(4, 60px);
    }
`;
