import React from 'react' 
const OutfitText = () =>{ return (
    <div>
        <h1>Klubbdräkt</h1>
        <p>Klubbkollektion för seniorer och ungdomar</p>
        <h2>Vid träning och tävling</h2>
        <p>Djurgårdens officiella matchtröja är ljusblå (vid bortamatch kan mörkblå förekomma). Mörkblå byxor.</p>
        <p>Vid all träning rekommenderas aktuella klubbtröjor.</p>
        <p>Särskilda regler gäller när klubben representeras i officiella sammanhang (seriespel, mästerskap osv.).</p>
        <h2>Beställa och köpa</h2>
        <p>För seniorer som representerar klubben i officiella tävlingar och seriespel finns overall och matchställ att köpa. Kontakta kansliet för rätt modell och ev. rabatter vid köp på Djurgårdens officiella butik.</p>
        <p>För träningsspel (inkl. uppvärmning vid representation) och deltagande i pingisskolorna förmedlas aktuella tröjor via kansliet.</p>
    </div>
    ); 
};

export default OutfitText