import React from 'react';
import Page from '../components/PageTemplate'
import HomePageText from '../data/TextAreaInput/HomePageText'

const Home = () => {
    const titleSectionInput = {
        title: 'Djurgårdens IF Bordtennis ',
        image: 'collage2_2.png',
        bgTint1: "rgba(0, 37, 84, 0.2)",
        bgTint2: "rgba(0, 37, 84, 0.5)",
        bgTint3: "rgba(0, 37, 84, 0.8)"
    }
    return ( <
        Page titleSection = { titleSectionInput }
        textArea = { < HomePageText / > }
        />
    )
}

export default Home