import { urlSafeDecode } from '@aws-amplify/core';
import React from 'react'
import StadionImage from '../../images/djurgardsfestival_stadion_small.jpeg'
import Rackets from '../../images/rackets_500.jpg'
import KickOffImage from '../../images/kick_off_fall_2023_500.png'
import Ligacupen from '../../images/ligacupen_small.jpg'
import SommarTavling from '../../images/ostersjomasterskapen_small.jpg'
import Div2Premier from '../../images/div2N-small.jpg'
const HomePageText = () =>{ return (
    <div>
        <h1>Välkommen till Djurgården Bordtennis</h1>
        <div>
            <p>Välkommen att spela pingis med Djurgården i någon av våra träningsgrupper - seniorer, ungdomsgruppen samt de olika pingisskolorna. Från motionspingis till tävlings- och seriespel för damer och herrar i alla åldrar.</p>
        </div>
        <div class ="flex-container">
            <div class="flex-item">
            <h2>AKTUELLT</h2>


                 {/* <img class="main-flex-item-image"src={AutumnImage} alt="Dags för höst och bordtennisträning."/> */}

                <p>
                    Pågående pingisfeber i Sverige har märkts av även hos oss och fortsätter med full kraft. Pingisskolan är i full gång med rekord i antalet nybörjargrupper och många spelsugna barn i fortsättningsgrupperna.
                </p>
         
                {/* <h3>Säsongen 2024/2025 är igång</h3> */}
                <p>
                    Säsongen 2024/2025 är i full gång, vi ser fram emot en spännande säsong för alla 12 lag.
                </p>
         
                 <img class="main-flex-item-image" src={Div2Premier}
                alt="Kvinnor som spelar bordtennis för Djurgården."/>

                <p>Premiär i div2 för ett av våra damlag.</p> 
                {/* <p>
                    Det går att följa den spännande upplösningen på Damer div 3,
                     som är högsta Stockholmsserien, där Djurgården är serieledare och som avgörs den 16 mars i Liljeholmshallen.
                     Går allt bra kan det bli ett till lag i div 2 nästa säsong. Kom gärna och heja på!</p> */}

                {/* <h3>Sommarläger 13-16/6</h3>
                <p>Planeringen inför årets sommarläger är i full gång, maila ditt intresse till kansliet via <a href = "mailto: difbtx@gmail.com">difbtx@gmail.com</a>. Troligen avslutas även årets läger med en klubbtävling.</p> */}
                               <p>
                    Extra spännande blir det den 13e december i småländska Kosta när våra damer ska möta Sveriges bästa pingisspelare i Ligacupens Final Four.
                    </p>
                           </div> <div class="flex-item">
                <h3>Pingisskolan</h3>
                {/* <div style={{
                    position: 'relative',
                    overflow: 'hidden',
                }}>
                <img class="background-image-stripes"src={Rackets}
                alt="Nya pingisgrupper efter Sveriges framgångar i EM."/>
                <div class="gold-background stripe-top-left">
                    <p>Ny omgång</p>
                </div>
                <div class="red-background stripe-lower-right">
                    <p>Anmäl innan xx/yy</p>
                </div>
                </div> */}

                <p>
                Höstens andra omgång av Pingisskolan startar den 10e november, efteranmälan i möjligaste mån.
                </p>
                <p>Pingisskolan vänder sig till barn från ca 6-7 år och äldre. För att få plats i Pingisskolan kontaktar du kansliet via <a href = "mailto: difbtx@gmail.com">difbtx@gmail.com </a> 
                  för anmälan och aktuell information om lediga platser.
                 För flickor finns även möjlighet att ange intresse att ingå i grupp med jämnåriga tjejer.</p>
            {/*          <h4>Kick off för damlagen 2023/2024</h4>
                <p>
                    Damlagen startade säsongen med en kick-off med deltagare från alla fyra lag. De startade sedan seriespelet med fint spel, och serieledning för B-laget i sin serie. Bra jobbat!
                </p>
                 <img class="main-flex-item-image"src={KickOffImage} alt="Pingisspelande damer, flickor och tjejer från Djurgården."/> */}
{/*
                <h3>Säsongen 2023/24</h3>
                 <p>Välkommen till en ny säsong med Djurgården Bordtennis.</p>
                 <p>Vill du spela pingis med oss - tävla, träna seriöst eller mest motionsspela - så kontakta kansliet, <a href = "mailto: difbtx@gmail.com">difbtx@gmail.com</a>, för aktuell information. Det finns grupper för alla beroende på din ambitionsnivå, förkunskaper, ålder osv. För de yngre finns särskild Pingisskola.</p> */}
          
                <h3>Nya seniorer</h3>
                <p>För dig som inte har spelat organiserat tidigare, eller som vill återuppta pingisen, går det bra att anmäla sig till någon av vuxengrupperna för motionärer eller tävlingsspelare. Kontakta kansliet på  <a href = "mailto: difbtx@gmail.com">difbtx@gmail.com</a> för att få aktuell information om lämplig grupp för din nivå.</p>

                {/* <p>Seriespelet är nu igång för alla våra tio lag. De består av ett flicklag, tre damlag, två ungdomslag, två herrlag samt två lag i veteranserierna.</p> */}
                <h3>Welcome to Djurgården Bordtennis!</h3>
                <p>Table-tennis for everyone, regardless of age and skills. For more information please send an e-mail to <a href = "mailto: difbtx@gmail.com">difbtx@gmail.com</a>. You will be contacted as soon as possible.</p>
            </div>
        </div>
        <div>
            <p>Vi ses i pingishagen!</p>
            <p>Djurgården Bordtennis</p>
            <p>Kansliet e-post: <a href = "mailto: difbtx@gmail.com">difbtx@gmail.com</a></p>
        </div>
    </div>
    );
};

export default HomePageText
