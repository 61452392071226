// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TtSchoolRegistrationStatus = {
  "PENDING": "PENDING",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED",
  "CANCELLED": "CANCELLED"
};

const { TTSchoolEvent, TTSchoolRegistration } = initSchema(schema);

export {
  TTSchoolEvent,
  TTSchoolRegistration,
  TtSchoolRegistrationStatus
};