import React from 'react'
import { Nav, NavMenu, Logo, MenuBars, MenuBarsIcon } from './NavbarElements';
import difLogo from '../../images/dif_logo.svg';
import menuIcon from '../../images/menu-icon.svg';

const Navbar = ({toggle}) => {
    return (
        <Nav>
            <Logo to="/">
                <img src={difLogo} alt="DIF" width='78px' height='78px'/>
            </Logo>
            <NavMenu>
                {/* <NavButtonWrapper>
                    <NavMenuLink to='/anmal-intresse' key='0'>
                            Anmäl intresse
                    </NavMenuLink>
                    <NavMenuLink to='/logga-in' key='1'>
                            Logga in
                    </NavMenuLink>
                </NavButtonWrapper> */}
                <MenuBars onClick={toggle}>
                    Meny
                    <MenuBarsIcon>
                        <img src={menuIcon} alt="" width='21px' height='21px' />
                    </MenuBarsIcon>
                </MenuBars>
            </NavMenu>
        </Nav>
    )
}

export default Navbar

