import React from 'react'
import {TitleSectionWrapper, TitleSectionText, SubTitleSectionText} from './TitleSectionElements.js'

const TitleSection = ({title, subTitle, bgImage, bgTint1, bgTint2, bgTint3}) => {
    var imageBaseName = bgImage.substring(0,bgImage.indexOf("."));
    var imageEnding = bgImage.substring(bgImage.indexOf("."));
    var baseImage = require("../../images/" + imageBaseName + "/" + bgImage);
    var bgImage_480 = require("../../images/" + imageBaseName + "/" + imageBaseName + "_480" + imageEnding);
    var bgImage_768 = require("../../images/" + imageBaseName + "/" + imageBaseName + "_768" + imageEnding);
    var bgImage_1366 = require("../../images/" + imageBaseName + "/" + imageBaseName + "_1366" + imageEnding);
    
    let subTitleComponent;
    if (subTitle) {
        subTitleComponent = <SubTitleSectionText><br />{subTitle}</SubTitleSectionText>;
    }

    return (
        <TitleSectionWrapper 
            bgImage = {baseImage}
            bgImage_480 = {bgImage_480}
            bgImage_768 = {bgImage_768}
            bgImage_1366 = {bgImage_1366}
            bgTint1 = {bgTint1}
            bgTint2 = {bgTint2}
            bgTint3 = {bgTint3}>
            <TitleSectionText>
                {title}
            </TitleSectionText>
            {subTitleComponent}
        </TitleSectionWrapper>
    )
}

export default TitleSection
