import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import Navbar from './components/Navbar';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { GlobalStyle } from './components/Styles/GlobalStyles';
import Dropdown from './components/Dropdown';
import Home from './pages/Home';
import MyPages from './pages/MyPages';
import ContactUs from './pages/ContactUs';
import Values from './pages/Values';
import TrainingClasses from './pages/TrainingClasses';
import ClubHistory from './pages/ClubHistory';
import SubmitInterest from './pages/SubmitInterest';
import Club from './pages/Club';
import Teams from './pages/Teams';
import Outfits from './pages/Outfits';

Amplify.configure(awsconfig);

I18n.setLanguage("en-SE");

I18n.putVocabulariesForLanguage("en-SE", {
  [Translations.SIGN_IN_HEADER_TEXT]: "Logga in på ditt konto",
  [Translations.SIGN_IN_ACTION]: "Logga in",
  [Translations.USERNAME_LABEL]: "Användarnamn *",
  [Translations.USERNAME_PLACEHOLDER]: "Ange ditt användarnamn",
  [Translations.PASSWORD_LABEL]: "Lösenord *",
  [Translations.PASSWORD_PLACEHOLDER]: "Ange ditt lösenord",
  [Translations.FORGOT_PASSWORD_TEXT]: "Glömt ditt lösenord?",
  [Translations.RESET_PASSWORD_TEXT]: "Återställ lösenord",
  [Translations.NO_ACCOUNT_TEXT]: " ",
  [Translations.CREATE_ACCOUNT_TEXT]: " ",
  [Translations.SIGN_OUT]: "Logga ut"
  
});

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="App">
        <Router>
          <GlobalStyle />
          <Navbar toggle={toggle}/>
          <Dropdown isOpen={isOpen} toggle={toggle}/>
  
          <Switch>
              <Route exact path="/">
                  <Home />
              </Route>
              <Route path="/anmal-intresse">
                  <SubmitInterest />
              </Route>
              <Route path="/logga-in">
                  <MyPages/>
              </Route>
              <Route path="/vardegrund">
                  <Values />
              </Route>
              <Route path="/pingisskolor">
                  <TrainingClasses />
              </Route>
              <Route path="/klubbens-historia">
                  <ClubHistory />
              </Route>
              <Route path="/djurgarden-bordtennis">
                  <Club />
              </Route>
                <Route path="/klubbdrakt">
                  <Outfits />
              </Route>
                <Route path="/lagen">
                  <Teams />
              </Route>
          </Switch>
        </Router>
    </div>
  );
}

export default App;
