import {createGlobalStyle} from 'styled-components';
import DIFDisplayWebFontEOT from '../../fonts/difdisplay/difdisplay-webfont.eot';
import DIFDisplayWebFontWOFF2 from '../../fonts/difdisplay/difdisplay-webfont.woff2';
import DIFDisplayWebFontWOFF from '../../fonts/difdisplay/difdisplay-webfont.woff';
import DIFDisplayWebFontTTF from '../../fonts/difdisplay/difdisplay-webfont.ttf';
import DIFDisplayWebFontSVG from '../../fonts/difdisplay/difdisplay-webfont.svg';

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;

        font-family: 'Didact Gothic', sans-serif;
        //font-family: 'dif_displaymedium';

        @font-face {
            font-family: 'dif_displaymedium';
            src: url(${DIFDisplayWebFontEOT});
            src: url(${DIFDisplayWebFontEOT}?#iefix) format('embedded-opentype'),
                 url(${DIFDisplayWebFontWOFF2}) format('woff2'),
                 url(${DIFDisplayWebFontWOFF}) format('woff'),
                 url(${DIFDisplayWebFontTTF}) format('truetype'),
                 url(${DIFDisplayWebFontSVG}?#dif_displaymedium) format('svg');
            font-weight: normal;
            font-style: normal;
        }

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        --amplify-primary-color: #002554;
        --amplify-primary-tint: #32baf2;
        --amplify-primary-shade: #32baf2;

        --amplify-font-family: 'Didact Gothic', sans-serif;

        amplify-authenticator {
            margin-top: 80px;
            padding: 5px;
        }
    }
`;