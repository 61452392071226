/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:140f47d4-dad7-4e2c-a7b6-fe7447a66ae2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_iUBANd98W",
    "aws_user_pools_web_client_id": "6oq335vttoo9rqld26q7ovbb6l",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fa7uv5e511.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qtti7447hbc5jftdrugt4d7m3q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vyxt5iwu55cdxeunhqd4brmpoy"
};


export default awsmobile;
