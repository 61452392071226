import React from 'react'
import Page from '../components/PageTemplate'
import TrainingClassesText from '../data/TextAreaInput/TrainingClassesText'


const TrainingClasses = () => {
    const titleSectionInput = {
        title: '',
        image: 't-shirt.jpg',
        bgTint1: "rgba(0, 37, 84, 0.0)",
        bgTint2: "rgba(0, 37, 84, 0.0)",
        bgTint3: "rgba(0, 37, 84, 0.0)"
    }
    return ( <
        Page titleSection = { titleSectionInput }
        textArea = { < TrainingClassesText / > }
        />
    )
}

export default TrainingClasses