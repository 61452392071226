import styled from 'styled-components/macro'

export const TitleSectionWrapper = styled.div `
  background:
    linear-gradient(
      ${props => props.bgTint1 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint2 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint3 || "rgba(0, 0, 0, 0.0)"}
    ),
    url(${props => props.bgImage_480});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  border-image-repeat: stretch;

  @media screen and (min-width: 240px){
    background:
    linear-gradient(
      ${props => props.bgTint1 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint2 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint3 || "rgba(0, 0, 0, 0.0)"}
    ),
    url(${props => props.bgImage_768});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    border-image-repeat: stretch;
  }

  @media screen and (min-width: 480px){
    background:
    linear-gradient(
      ${props => props.bgTint1 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint2 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint3 || "rgba(0, 0, 0, 0.0)"}
    ),
    url(${props => props.bgImage_1366});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    border-image-repeat: stretch;
  }
  @media screen and (min-width: 1024px){
    background:
    linear-gradient(
      ${props => props.bgTint1 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint2 || "rgba(0, 0, 0, 0.0)"},
      ${props => props.bgTint3 || "rgba(0, 0, 0, 0.0)"}
    ),
    url(${props => props.bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    border-image-repeat: stretch;
  }
`;

export const TitleSectionText = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  height: 0vh;
  font-family: 'dif_displaymedium';
  color: #fff;
  letter-spacing: .5em;
  font-size: 6.5vw;

  padding-top: 9.8rem;
  padding-bottom: 8.8rem;
  text-align: center;
  margin-left: 0vw;
  @media screen and (min-width: 480px){
    height: 15vh;
  }
  @media screen and (min-width: 768px) {
    font-size: 50px;
    line-height: 75px;
    height: 30vh;
  }
  @media screen and (min-width: 1024px) {
    height: 60vh;
  }
  @media screen and (min-width: 1366px) {
    height: 90vh;
  }
`;

export const SubTitleSectionText = styled.div `
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
  font-family: 'Didact Gothic', sans-serif;
  color: #fff;
  font-size: 4.5vw;
  margin-top: -50vh;
  text-align: center;

  @media screen and (min-width: 768px) {
      font-size: 35px;
  }
`;