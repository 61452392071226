import React from 'react'
import styled from 'styled-components/macro'
//import old from '../images/old.jpeg'
import old2 from '../images/old2.jpeg'
import TitleSection from '../components/TitleSection'


const ClubHistoryWrapper = styled.div`
    //background: red;
`;

const TextArea = styled.div`
    max-width: 70%;
    margin-top: 50px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 100px;
    font-size: 20px;
    text-align: left;
    font-family: 'Didact Gothic', sans-serif;
`;

const ClubHistory = () => {
    return (
        <ClubHistoryWrapper>
        <TitleSection 
            title = {
                `Klubbens Historia`} 
            bgImage={old2}
            bgTint1={"rgba(207, 181, 113, 0.3)"}
            bgTint2={"rgba(207, 181, 113, 0.3)"}
            bgTint3={"rgba(207, 181, 113, 0.3)"}/>

            <TextArea>
                Djurgårdens bordtennisspelande startade hösten
                1947. Bland förgrundsgestalterna för dryga
                halvseklet sedan märks urdjurgårdaren Bengt
                Grive, legendarisk sportjournalist och en av
                Sveriges bästa bordtennisspelare under 40- och
                50-talen med flera SM-tecken, och Tage ”Flisan”
                Flisberg en spelare av världsklass, men med
                rötter i Norrköping.
                Redan första säsongen, 1948, blev det storslam
                för Djurgården med SM-guld i singel, dubbel och
                i lag! Tage Flisberg vann såväl singel som dubbel
                - i par med Arne Neidenmark – och tillsammans
                med Bengt Grive stod trion som komfortabla
                segrare också i Allsvenskan. Föga överraskande
                representerade Flisberg och Grive även det
                svenska landslaget i VM 1948 som den gången
                gick i London. Men några VM-titlar blev det just
                inte.<br />
                <br /> 
                <h2>Att vara en riktig lirare.</h2>  
                <br />
                Inom pingisen har det alltid varit något extra att
                bli betraktad som en riktig lirare. Och att göra
                det lilla extra med den fjäderlätta celluloidbollen
                har alltid varit möjligt för just pingisspelare. Inte
                minst gällde det för Grivegenerationen som
                under de tidiga djurgårdsåren ägnade sig åt rena
                uppvisningsmatcher med inslag av allehanda
                bollkonster och ”gags”. Många är fortfarande
                dom som minns TV-inslag med en
                ballongplockande Bengt Grive iförd hög hatt och
                frack tillsammans med senare tids
                landslagsstjärnor. Inte undra på att det blev SM-
                guld i singel för Grive 1951, och ett i dubbel och
                två lag-SM.<br />
                <br />
                <h2>38 SM-guld… </h2> 
                <br />
                Väl försedda med mästerskap är också ett par
                andra legender. Med Björne Mellströms fyra
                individuella SM-tecken, två dubbelvinster och tre
                lagguld var han en verklig stöttepelare under
                50-talet. Damlaget var inte sämre utan lade
                beslag på fem SM-guld i rad under andra halvan
                av 50-talet. Signhild Tegnér klarade hem fyra av
                dessa och därtill två SM i singel och två i dubbel.
                Hittills har 38 svenska mästerskap tagits hem av
                Djurgården. Under 60-talet fanns det
                möjligheter att värva Kjell ”Hammaren”
                Johansson till Djurgården. Så blev inte fallet
                trots att Hammaren själv var intresserad. Den
                verkliga guldepoken hittills får därför tillskrivas
                50-talet, men det senaste SM-tecknet tog Uffe
                Thorsell i dubbel 1980.
            </TextArea>
            
        </ClubHistoryWrapper>
    )
}

export default ClubHistory
